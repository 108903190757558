
@font-face {
  font-family: 'Averta-Regular';
  src:  url('../fonts/Averta-Regular.eot?#iefix') format('embedded-opentype'),
  		url('../fonts/Averta-Regular.otf')  format('opentype'),
	    url('../fonts/Averta-Regular.woff') format('woff'), 
	    url('../fonts/Averta-Regular.ttf')  format('truetype'), 
	    url('../fonts/Averta-Regular.svg#Averta-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Thin';
  src: 	url('../fonts/Averta-Thin.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-Thin.otf')  format('opentype'),
     	url('../fonts/Averta-Thin.woff') format('woff'),
    	url('../fonts/Averta-Thin.ttf')  format('truetype'),
	    url('../fonts/Averta-Thin.svg#Averta-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-ThinItalic';
  src: 	url('../fonts/Averta-ThinItalic.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-ThinItalic.otf')  format('opentype'),
    	url('../fonts/Averta-ThinItalic.woff') format('woff'),
	    url('../fonts/Averta-ThinItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-ThinItalic.svg#Averta-ThinItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-SemiboldItalic';
  src: 	url('../fonts/Averta-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
   		url('../fonts/Averta-SemiboldItalic.otf')  format('opentype'),
	    url('../fonts/Averta-SemiboldItalic.woff') format('woff'),
	    url('../fonts/Averta-SemiboldItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-SemiboldItalic.svg#Averta-SemiboldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-RegularItalic';
  src: 	url('../fonts/Averta-RegularItalic.eot?#iefix') format('embedded-opentype'),
   		url('../fonts/Averta-RegularItalic.otf')  format('opentype'),
	    url('../fonts/Averta-RegularItalic.woff') format('woff'),
	    url('../fonts/Averta-RegularItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-RegularItalic.svg#Averta-RegularItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-LightItalic';
  src: 	url('../fonts/Averta-LightItalic.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-LightItalic.otf')  format('opentype'),
	    url('../fonts/Averta-LightItalic.woff') format('woff'),
	    url('../fonts/Averta-LightItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-LightItalic.svg#Averta-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Light';
  src: 	url('../fonts/Averta-Light.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-Light.otf')  format('opentype'),
	    url('../fonts/Averta-Light.woff') format('woff'),
	    url('../fonts/Averta-Light.ttf')  format('truetype'),
	    url('../fonts/Averta-Light.svg#Averta-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-ExtrathinItalic';
  src: 	url('../fonts/Averta-ExtrathinItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Averta-ExtrathinItalic.otf')  format('opentype'),
	    url('../fonts/Averta-ExtrathinItalic.woff') format('woff'),
	    url('../fonts/Averta-ExtrathinItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-ExtrathinItalic.svg#Averta-ExtrathinItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Extrathin';
  src: 	url('../fonts/Averta-Extrathin.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-Extrathin.otf')  format('opentype'),
	    url('../fonts/Averta-Extrathin.woff') format('woff'),
	    url('../fonts/Averta-Extrathin.ttf')  format('truetype'),
	    url('../fonts/Averta-Extrathin.svg#Averta-Extrathin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-ExtraboldItalic';
  src: 	url('../fonts/Averta-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Averta-ExtraboldItalic.otf')  format('opentype'),
	    url('../fonts/Averta-ExtraboldItalic.woff') format('woff'),
	    url('../fonts/Averta-ExtraboldItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-ExtraboldItalic.svg#Averta-ExtraboldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Extrabold';
  src: 	url('../fonts/Averta-Extrabold.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-Extrabold.otf')  format('opentype'),
	    url('../fonts/Averta-Extrabold.woff') format('woff'),
	    url('../fonts/Averta-Extrabold.ttf')  format('truetype'),
	    url('../fonts/Averta-Extrabold.svg#Averta-Extrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-BoldItalic';
  src: 	url('../fonts/Averta-BoldItalic.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-BoldItalic.otf')  format('opentype'),
	    url('../fonts/Averta-BoldItalic.woff') format('woff'),
	    url('../fonts/Averta-BoldItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-BoldItalic.svg#Averta-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Bold';
  src: 	url('../fonts/Averta-Bold.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-Bold.otf')  format('opentype'),
	    url('../fonts/Averta-Bold.woff') format('woff'),
	    url('../fonts/Averta-Bold.ttf')  format('truetype'),
	    url('../fonts/Averta-Bold.svg#Averta-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-BlackItalic';
  src: 	url('../fonts/Averta-BlackItalic.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-BlackItalic.otf')  format('opentype'),
	    url('../fonts/Averta-BlackItalic.woff') format('woff'),
	    url('../fonts/Averta-BlackItalic.ttf')  format('truetype'),
	    url('../fonts/Averta-BlackItalic.svg#Averta-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Averta-Black';
  src: 	url('../fonts/Averta-Black.eot?#iefix') format('embedded-opentype'),
    	url('../fonts/Averta-Black.otf')  format('opentype'),
	    url('../fonts/Averta-Black.woff') format('woff'),
	    url('../fonts/Averta-Black.ttf')  format('truetype'),
	    url('../fonts/Averta-Black.svg#Averta-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}


#cookie-bar {
    background: $humble;
    border-top: 1px solid $humble;
    height: auto;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    font-family: 'Averta-Light';
    &.fixed {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
    }
    &.fixed.bottom {
        bottom: 0;
        top: auto;
    }
     p{
        clear: both;
        margin: 0;
        padding: 0;
        margin-bottom:15px;
        color: #fff;
        font-size: 16px;
        @media only screen and (max-width: 480px){
          font-size: 14px;
        }
    }
     a {
        color: #fff;
        display: inline-block;
        border-radius: 0px;
        text-decoration: none;
        padding: 5px 15px;
        margin-left: 8px;
        margin-bottom: 5px;
        border: #fff solid 1px;
        text-transform: uppercase;
    }
    .cb-policy,
    .cb-enable{     
        background: #fff;
        border:1px solid #fff;
        color: $humble;
        -webkit-transition: all .2s ease-in-out;
           -moz-transition: all .2s ease-in-out;
            -ms-transition: all .2s ease-in-out;
             -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
        &:hover{
            background: $humble;
            color: #fff;
        }
    }
}

.link-logo-footer{
  color: #fff;
  svg{
    max-width: 100px;
    width: 100%;
    fill: #fff;
    height: auto;
    path{
      fill: #fff;
    }
  }
}

.page-footer{
  .last-content-wrapper{
    bottom: 0;
    .made-by,.humble-mark{
      top: 0;
      padding: 25px 0;
    }
  }
} 

.main-logo{
  svg{
    path{
      stroke: none;
    }
  }
}

.home-page{
  .introduction, .testimonial-section-wrapper{
    padding-top: 50px;
    
  }
  .image-blocks{
    padding-top: 50px;
  }
  .benefits{
    padding-top: 50px;
    margin-bottom: 0;
  }
}

.who-we-are-page{
  .page-content{
    .full-hashtag, .about{
      padding-top: 50px;
    }
    .to-section{
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
}

.what-we-do-page{
  .page-content{
    .about{
      margin-top: 50px;
      margin-bottom: 250px;
      padding-top: 200px;
    }
    .to-section{
      margin-top: 0px;
      margin-bottom: 200px;
    }
    .mission{
      padding-top: 0;
    }
    .humble-brands{
      padding-top: 100px;
    }
  } 
}


.our-philosophy-page{
  .image-blocks, .benefits{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 0px;
    @media only screen and (max-width: 991px){
      padding-top: 0px;
    }
  }
}

.contact-page{
  .page-content{
    .contacts {
      padding-top: 100px;
    }  
  } 
}


  
p{
    margin-bottom:15px;  
  }



.the-founder-header.introduction{
  padding:0;
  font-family: Averta-Regular;
}

  .the-founder{
      padding-top: 50px;
       @media only screen and (max-width: 767px){
           padding-top: 30px;
       }
    .image-wrapper{
      margin-top: 0;
    }


    &.image-blocks{
       margin: 0!important;
        padding-top: 50px!important;
      @media only screen and (max-width: 480px){
          margin-bottom: 0;
           padding-top: 0px!important;
      }
    }
    .answer-wrapper-text{
        background-color:$humble;
        margin-top: 0!important;
        padding:50px;
         @media only screen and (max-width: 991px){
             padding:30px;
         }
    }
    .founder-bg{
      width:100%;
      height: 100%;
      display: block;
      background-size:cover;
      min-height: 1000px;
      background-repeat:no-repeat;
        @media only screen and (max-width: 1200px){
            min-height: 600px;
            background-position: 0px;
        }
         @media only screen and (max-width: 767px){
           background-position: initial;
           min-height: 400px;
         }
    }
     li{          
        font-size: 24px;
     }
      p{
        color: #fff!important;
        @media only screen and (max-width: 1200px){
          font-size: 20px!important;
          line-height: 28px!important;
        }
         @media only screen and (max-width: 991px){
          font-size: 16px!important;
          line-height: 22px!important;
        }
        
      }
      a{
        font-family: Averta-Regular;
         color: #000!important;
           font-weight: bold;
      }
  }

